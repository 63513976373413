import { Select } from 'antd';
import styled, { css } from 'styled-components';
import Modal from '../Modal';

export const ModalCheckoutContainer = styled(Modal)`
  width: fit-content !important;
  .ant-modal-centered::before {
    height: 45%;
  }

  .ant-modal-content {
    background: #f9f7f3d6 !important;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    .ant-modal-close{
      .ant-modal-close-x{
      border-radius: 50%;
      svg{
      fill: rgba(252, 250, 251, 0.6);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);      border-radius: 50%;
      }
      }
    }
  }
  @media screen and (max-width: 1052px) {
  width: 75%!important;

  }
  @media screen and (max-width: 834px) {
  width: 100%!important;

  }
`;
export const ModalCheckoutContent = styled.div`
  display: grid;
  grid-template-columns: ${props => props.isSubscribed ? '0fr':'0fr 0fr 0fr'}  ;
  // grid-template-rows: 0fr 0fr;
  gap: 16px;

  min-height: 360px;

  padding: 24px;
  margin-top: 40px;
  .switch {
      left: 32px;
  }
  @media screen and (min-width: 834px) {
    .switch {
      grid-row: 2;
      grid-column: 2;
      align-self: end;
    }
  }

  @media screen and (max-width: 1052px) {
          display: flex;
          flex-direction: column;
          width: fit-content;
          place-self: center;
          min-height: unset;
  }

`;
export const SubscribedUntilContainer = styled.div`
  display: grid;
  text-wrap-mode: nowrap;
  width: fit-content;
  grid-auto-flow: row;
  place-items: center;
  place-content: center;
  gap: 8px;
`;
export const ProductContainer = styled.div`
  display: grid;
  // grid-template-rows: repeat(5, 0fr);
  height: fit-content;
  width: fit-content;
  gap: 16px;
  padding: 8px;
  div {
    align-items: center;
    display: flex;
    gap: 8px;
  }
  button{
    color: white !important;
  }
  ${(props) => props.shouldHighlight ? css`
    background: linear-gradient(#f9f7f3d6, #f9f7f3d6) padding-box, linear-gradient(90deg, #eece66 0%, #ff7d64 75%) border-box;
    border: 2px solid transparent;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    ` : css`
      background: linear-gradient(#f9f7f3d6, #f9f7f3d6) padding-box, linear-gradient(90deg, #675f7287 0%, #675f7287 75%) border-box;
      border: 2px solid transparent;
      border-radius: 8px;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      `}

`;
export const PortalBtn = styled.button`
  &:hover{
    cursor: pointer;
  }
  margin-top: 8px;
  background: linear-gradient(#f9f7f3d6, #f9f7f3d6) padding-box, linear-gradient(90deg, #eece66 0%, #ff7d64 75%) border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  ${props => props.isBlocked && css`
    opacity: 0.5;
    pointer-events: none;
    `}
  padding: 8px 16px;
`;
export const SelectCheckoutDuration = styled(Select)`
.ant-select-selection{
border: 1px solid transparent;
background-color: transparent;
font-size: 26px;
font-weight: 600;
line-height: 30px;
color: var(--accent-color);
.ant-select-selection__rendered{
  margin-left: 0;
}

}
.ant-select-dropdown{
.ant-select-dropdown-menu{
.ant-select-dropdown-menu-item{
font-size: 26px;
font-weight: 600;
line-height: 30px;
color: var(--accent-color);
}
}
}


  `;
