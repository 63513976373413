import styled, { css } from 'styled-components';
import { layoutSizes } from '../../constants';
import { minWidthQuery } from '../../utils';
import config from './config';

type Props = {
  noPadding: boolean;
  fluid: boolean;
  program: any;
  isHome: boolean;
};

export const Grid = styled.div<Partial<Props>>`
  padding-left: auto;
  padding-right: auto;

  ${(p) =>
    !p.noPadding &&
    css`
      ${layoutSizes.map(
        (t) =>
          config(p).media[t]`
        padding-right: ${(p) => `${config(p).outerMargin[t]}px`};
        padding-left: ${(p) => `${config(p).outerMargin[t]}px`};
      `
      )}
    `}

  ${(p) =>
    !p.fluid &&
    css`
      ${layoutSizes.map(
        (t) =>
          config(p).container[t] &&
          config(p).media[t]`
        width: ${(p) => config(p).container[t]}px;
      `
      )}
    `}

    ${(props) =>
    props.program &&
    minWidthQuery('md')(`
        max-width: 700px;
        width: 700px;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
    `)}
    ${(props) =>
    props.program &&
    minWidthQuery('lg')(`
        max-width: 700px;
        width: 700px;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
    `)}
    ${(props) =>
    props.isHome &&
    css`
      margin-bottom: 64px;
    `}
`;
