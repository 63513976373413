import { useComputed } from '@preact/signals-react';
import { Col } from 'antd';
import { AiBookIcon, CheckListIcon, Navigation03Icon, Tick01Icon } from 'hugeicons-react';
import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { css } from 'styled-components';
import { PageRoutes } from '../../../constants';
import { ReactComponent as Home } from '../../../images/home.svg';
import { ReactComponent as LuminousSvg } from '../../../images/luminous.svg';
import { ReactComponent as NewLogoSvg } from '../../../images/new-logo.svg';
import { userSignal } from '../../../signals/global.signals';
import { Typography } from '../../../stories/Typography';
import Button from '../../Button';
import ModalCheckoutLink from '../../ModalCheckoutLink/ModalCheckoutLink';
import { isSubscribedComputed, SubscriptionSignal } from '../../ModalCheckoutLink/ModalCheckoutLink.signal';
import UserProfile from '../../UserProfile';
import { footerNavigationBarVisibleBreakpoint } from '../constants';
import { HeaderContentWrapper } from '../styled';
import {
  HeaderContent,
  HeaderContentNav,
  HeaderLinkContent,
  Logo,
  MainHeaderLinks,
  OpenSubscriptionBtn
} from './styled';



export function DefaultHeader({
  loggedInUser,
  changeOnMobile,
  isMobile,
  headerNavButtonsWithHeaderSticky,
  isScrolling,
  showHeaderActions
}) {
  const computedUserSignal = useComputed(() => userSignal.value);
  const isSubscribed = isSubscribedComputed.value;
  const { t } = useTranslation();
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const changeWidth = useCallback(() => {
    const newWidth = window.innerWidth;
    // Only update if the width actually changed
    if (newWidth !== width) {
      setWidth(newWidth);
    }
  }, [width]);
  const isWorkingForLuminous = loggedInUser?.email.includes('luminous') || loggedInUser?.id.toString() === '102';
  const handleBtnSubscriptionClick = useCallback(() => {
    SubscriptionSignal.value = { ...SubscriptionSignal.value, isSubscribing: true };
  }, []);
  useEffect(() => {
    const throttledChangeWidth = throttle(changeWidth, 250);
    window.addEventListener('resize', throttledChangeWidth);
    return () => {
      window.removeEventListener('resize', throttledChangeWidth);
    };
  }, [changeWidth]);

  return (
    <HeaderContentWrapper
      justifyContent="space-between"
      hide={isScrolling && headerNavButtonsWithHeaderSticky}>
      {width != null && (
        <>
          {changeOnMobile && width <= footerNavigationBarVisibleBreakpoint ? (
            <HeaderContent changeOnMobile>
              <HeaderLinkContent>
               {Boolean(isWorkingForLuminous) && <OpenSubscriptionBtn  isSubscribed={isSubscribed} onClick={handleBtnSubscriptionClick}>
                  <Typography color={css`var(--accent-color)`} fw="600" lh="14px" customStyles={css`
                  {${isSubscribed && css`
                    display: grid;
                      grid-auto-flow: column;
                      place-items: center;
                    `}  }
                    `}>
                    {' '}
                    {isSubscribed ? <><Tick01Icon size={16}/> { t('subscribed')}</> : t('subscribe_button')}
                  </Typography>
               </OpenSubscriptionBtn>}
                <Link
                  to={
                    PageRoutes.AI_SESSIONS +
                    `/${process.env.NODE_ENV === 'development' ? '17' : '71'}`
                  }
                  className="ai-assistant-link">
                  <AiBookIcon />
                </Link>
                <Link to={PageRoutes.PROFILE}>
                  <UserProfile
                    changeOnMobile
                    size="small"
                    pictureUrl={computedUserSignal.value?.picture?.formats.small.url}></UserProfile>
                </Link>
              </HeaderLinkContent>
              <Link to={loggedInUser.isAuthenticated ? PageRoutes.HOME : PageRoutes.LANDING}>
                <Logo changeOnMobile>
                  <div style={{ display: 'flex' }}>
                    <NewLogoSvg style={{ marginRight: '10px', width: '40px' }} />
                    <LuminousSvg style={{ marginTop: '8px' }} />
                  </div>
                </Logo>
              </Link>
            </HeaderContent>
          ) : (
            <>
              <Col md={10} lg={10}>
                <HeaderContent>
                  <Link to={loggedInUser.isAuthenticated ? PageRoutes.HOME : PageRoutes.LANDING}>
                    <Logo changeOnMobile>
                      <div style={{ display: 'flex' }}>
                        <NewLogoSvg style={{ marginRight: '10px', width: '57px' }} />
                        <LuminousSvg style={{ marginTop: '15px' }} />
                      </div>
                    </Logo>
                  </Link>
                  {loggedInUser.isAuthenticated && (
                    <>
                      <Link to={PageRoutes.HOME}>
                        <MainHeaderLinks
                          className={location.pathname === PageRoutes.HOME ? 'active' : ''}>
                          <Home className="home" />
                          {t('menu_dashboard')}
                        </MainHeaderLinks>
                      </Link>
                      {/* <Link to={PageRoutes.SESSIONS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.SESSIONS_LIBRARY ? 'active' : ''}>
                          <PlayIcon className="start" />
                          {t('profile_sessions')}
                        </MainHeaderLinks>
                      </Link> */}

                      <Link to={PageRoutes.PROGRAMS_LIBRARY}>
                        <MainHeaderLinks
                          className={
                            location.pathname === PageRoutes.PROGRAMS_LIBRARY ? 'active' : ''
                          }>
                          <Navigation03Icon className="start" />
                          {t('programs')}
                        </MainHeaderLinks>
                      </Link>
                      <Link to={PageRoutes.TO_DOS_LIBRARY}>
                        <MainHeaderLinks
                          className={
                            location.pathname === PageRoutes.TO_DOS_LIBRARY ? 'active' : ''
                          }>
                          <CheckListIcon className="start" />
                          {t('profile_to_dos')}
                        </MainHeaderLinks>
                      </Link>
                    </>
                  )}
                </HeaderContent>
              </Col>
              <Col md={2} lg={2}>
                <HeaderContentNav hiddenLogo={isScrolling && headerNavButtonsWithHeaderSticky}>
                  {isMobile &&
                    isScrolling &&
                    headerNavButtonsWithHeaderSticky &&
                    headerNavButtonsWithHeaderSticky.map((headerNavButton) => headerNavButton)}
                  {!isMobile &&
                    headerNavButtonsWithHeaderSticky &&
                    headerNavButtonsWithHeaderSticky.map((headerNavButton) => headerNavButton)}
                  {loggedInUser.isAuthenticated ? (
                    <>
                      {Boolean(isWorkingForLuminous) && <OpenSubscriptionBtn isSubscribed={isSubscribed} onClick={handleBtnSubscriptionClick}>
                        <Typography color={css`var(--accent-color)`} fw="600" lh="14px" customStyles={css`
                        {${isSubscribed && css`
                          display: grid;
                            grid-auto-flow: column;
                            place-items: center;
                          `}  }
                          `}>
                          {' '}
                          {isSubscribed ? <><Tick01Icon size={20}/> { t('subscribed')}</> : t('subscribe_button')}
                        </Typography>
                      </OpenSubscriptionBtn>}

                      <Link
                        to={
                          PageRoutes.AI_SESSIONS +
                          `/${process.env.NODE_ENV === 'development' ? '17' : '71'}`
                        }
                        className="ai-assistant-link">
                        <AiBookIcon />
                      </Link>
                      <Link to={PageRoutes.PROFILE}>
                        <UserProfile
                          size="small"
                          pictureUrl={computedUserSignal.value?.picture?.formats.small.url}></UserProfile>
                      </Link>
                    </>
                  ) : (
                    showHeaderActions && (
                      <Button styles="secondary" size="large" href={PageRoutes.LOGIN}>
                        {t('login_button')}
                      </Button>
                    )
                  )}
                </HeaderContentNav>
              </Col>
            </>
          )}
        </>
      )}
      <ModalCheckoutLink />
    </HeaderContentWrapper>
  );
}
