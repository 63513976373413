import { computed, signal } from "@preact/signals-react";
import { userSignal } from "../../signals/global.signals";



export const SubscriptionSignal = signal < {isSubscribing: boolean | null, subscribedUntil: Date | null, isSubscribed: boolean} > ({isSubscribing: false, subscribedUntil: null, isSubscribed: false});

export const isSubscribingComputed = computed(() =>
  SubscriptionSignal.value.isSubscribing
);
export const subscribedUntilComputed = computed(() => SubscriptionSignal.value.subscribedUntil)

export const isSubscribedComputed = computed(() => userSignal.value?.userSubscription?.subscriptionStatus === 'active' && new Date(userSignal.value?.userSubscription?.subscribedUntil) > new Date())
