import styled from 'styled-components';
import { maxWidthQuery, minWidthQuery } from '../../utils';
import { Col } from '../Col';
import { Row } from '../Row';

type HeaderWrapperProps = {
  withScrolledBg: boolean;
  sticky: boolean;
  scrolled: boolean;
  hide: boolean;
  newLayout: boolean;
  noHeaderBkg: boolean;
};

export const HeaderWrapper = styled.div<Partial<HeaderWrapperProps>>`
  position: relative;
  z-index: 1;
  background: rgba(252, 250, 251, 0.9);
  backdrop-filter: blur(24px);

  ${(props) =>
    props.withScrolledBg &&
    `
    background-color: transparent;
    transition: background-color 0.3s ease;
  `}

  ${(props) =>
    props.sticky &&
    `
    position: fixed;
    padding-left: 1rem;
    // padding-right: ${props.theme.utils.rem(props.theme.grid.outerMargin.xs)};
    padding-right: 1rem;
    top: 0;
    width: 100%;
    z-index: 1001;
  `}

  ${(props) =>
    props.sticky &&
    minWidthQuery('md')(`
      padding-left: ${props.theme.utils.rem(props.theme.grid.outerMargin.md)};
      padding-right: ${props.theme.utils.rem(props.theme.grid.outerMargin.md)};
  `)}

  ${(props) =>
    props.sticky &&
    minWidthQuery('lg')(`
      padding-left: ${props.newLayout ? '191px' : '48px'};
      padding-right: ${props.theme.utils.rem(props.theme.grid.outerMargin.lg)};
  `)}

  ${(props) =>
    props.withScrolledBg &&
    props.scrolled &&
    `
    background-color: rgba(252, 250, 251, 0.9);
  `}

  ${(props) =>
    props.hide &&
    `
    display: none;
  `}

  ${(props) => props.hide && minWidthQuery('md')(`display: block;`)}

  ${(props) =>
    props.noHeaderBkg &&
    `
    background: transparent;
    backdrop-filter: none;
  `}

  ${minWidthQuery('md')(`
    &.profile-header-wrapper {
      padding-top: 28px;
    }
  `)}
`;

type HeaderContentWrapperProps = {
  justifyContent: string;
  sessions: any;
  hide: boolean;
};

export const HeaderContentWrapper = styled(Row)<Partial<HeaderContentWrapperProps>>`
  display: flex;
  align-items: center;
  padding: 12px 0;
  height: auto;

  ${(props) =>
    props.justifyContent &&
    `
    justify-content: ${props.justifyContent};
  `}

  ${(props) =>
    maxWidthQuery('sm')(`
    padding-top: ${props.sessions ? '1rem' : '0.45rem'};
    padding-bottom: 0.45rem;

    ${
      props.hide &&
      `
      > div:first-of-type {
        display: none;
      }

      > div:last-of-type {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
      }
    `
    };
  `)};
`;

export const CloseContainer = styled(Col)`
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

type CloseWrapperProps = {
  closeButtonStroke: boolean;
};

export const CloseWrapper = styled.div<Partial<CloseWrapperProps>>`
  display: inline-block;
  cursor: pointer;

  .close-icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: relative;
    z-index: 10000;
    box-shadow: 0px 10.9091px 16.3636px rgba(0, 0, 0, 0.06),
      0px 1.36364px 4.09091px rgba(0, 0, 0, 0.04), 0px 0px 0.681818px rgba(0, 0, 0, 0.04);

    ${(props) =>
      props.closeButtonStroke &&
      `
      path{
        stroke: #1E2142;
      }
  `}
    ${maxWidthQuery('sm')(`
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-top: 7px;
    `)}
  }
`;

export const MobileFooter = styled.div`
  @media screen and (min-width: 810px) {
    display: none;
  }
  ${maxWidthQuery('md')(`
    background: #FFFFFF;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px 8px 0px 0px;
    padding: 15px 34px 12px 28px;
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;

      > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #675F72;
          margin: 6px 0 0;
          letter-spacing: -0.04em;
        }

        svg {
          height: 20px;
          width: auto;

          path {
            stroke: #675F72;
          }
        }

        &.active {
          p {
            color: #FC6453;
          }
          svg {
            path {
              stroke: #FC6453;
            }
          }
        }
      }
    }
  `)}
`;
