import { useSignalEffect } from '@preact/signals-react';
import { Select } from 'antd';
import { Cancel02Icon, Infinity01Icon } from 'hugeicons-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { useLazyGetUserProfileQuery } from '../../api/apiSlice';
import { userSignal } from '../../signals/global.signals';
import SwitchBtn from '../Switch/SwitchBtn';
import { Typography } from '../Typography';
import { isSubscribedComputed, isSubscribingComputed, SubscriptionSignal } from './ModalCheckoutLink.signal';
import {
  ModalCheckoutContainer,
  ModalCheckoutContent,
  PortalBtn,
  ProductContainer,
  SelectCheckoutDuration,
  SubscribedUntilContainer
} from './ModalCheckoutLink.style';
const { Option } = Select;



const ModalCheckoutLink = ({  onClose }) => {
  const {t} = useTranslation();
  const [localShowModal, setLocalShowModal] = useState(false);
  const [isProfessionalPrice, setIsProfessionalPrice] = useState(true);
  const [selectedDurations, setSelectedDurations] = useState({
    Lite: 'one_month',
    Pro: 'one_month',
    'Pro +': 'one_month'
  });
  const [hasTofetchuser, setHasToFetchUser] = useState(true);
  const products = process.env.REACT_APP_NODE_ENV === 'development' ? [
    {
      name: 'Lite',
      sessions: (
        <Typography fz="20px" fw="500" lh="1.2" padding="0 6px">
          6
        </Typography>
      ),
      sessionsIcon: null,
      programs: <Cancel02Icon />,

      buyBtnIds: {
        duration: {
          one_month: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 9
          }
          // three_month: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 10
          // },
          // one_year: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 11
          // }
        }
      },
      publishableKeyBtn:
        'pk_test_51PzxAsDfy7pLROjFmBkeyY2KZs7bRUTmEnebRpvDyAHIRo9vRWocXxMmcCSIdZrpezx59F4whif7RYMijGT6LLmu00qekamQbM'
    },
    {
      name: 'Pro',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Cancel02Icon />,

      buyBtnIds: {
        duration: {
          one_month: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 19
          },
          three_month: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 20
          },
          one_year: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 21
          }
        }
      },
      publishableKeyBtn:
        'pk_test_51PzxAsDfy7pLROjFmBkeyY2KZs7bRUTmEnebRpvDyAHIRo9vRWocXxMmcCSIdZrpezx59F4whif7RYMijGT6LLmu00qekamQbM'
    },
    {
      name: 'Pro +',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Infinity01Icon color={css`var(--accent-color)`} />,

      buyBtnIds: {
        duration: {
          one_month: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 49
          },
          three_month: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 50
          },
          one_year: {
            regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
            price: 51
          }
        }
      },
      publishableKeyBtn:
        'pk_test_51PzxAsDfy7pLROjFmBkeyY2KZs7bRUTmEnebRpvDyAHIRo9vRWocXxMmcCSIdZrpezx59F4whif7RYMijGT6LLmu00qekamQbM'
    }
  ] : [
    {
      name: 'Lite',
      sessions: (
        <Typography fz="20px" fw="500" lh="1.2" padding="0 6px">
          6
        </Typography>
      ),
      sessionsIcon: null,
      programs: <Cancel02Icon />,

      buyBtnIds: {
        duration: {
          one_month: {
            regular: 'buy_btn_1Qw9XBDfy7pLROjFXZKuMjyd',
            professional: 'buy_btn_1Qw9Y2Dfy7pLROjFqS5vhtgm',
            price: 9
          }
          // three_month: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 10
          // },
          // one_year: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 11
          // }
        }
      },
      publishableKeyBtn:
        'pk_live_51PzxAsDfy7pLROjF042S02mPUf9WrzUPsWQrvrwvD7gKQ85T2JDLWji2kqAG4EVbpZaQeQ7JcvSbX8TMzQvLRBFp00CbEcH8cL'
    },
    {
      name: 'Pro',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Cancel02Icon />,

      buyBtnIds: {
        duration: {
          one_month: {
            regular: 'buy_btn_1Qw9LPDfy7pLROjFPz4enOeI',
            professional: 'buy_btn_1Qw9MPDfy7pLROjFofvW2zkq',
            price: 29
          },
          three_month: {
            regular: 'buy_btn_1Qw9MxDfy7pLROjF1xaSFo0J',
            professional: 'buy_btn_1Qw9NNDfy7pLROjFeAJx57pr',
            price: 87
          },
          one_year: {
            regular: 'buy_btn_1Qw9OWDfy7pLROjFaVwGYzgN',
            professional: 'buy_btn_1QSEcUDfy7pLROjF0lTYbLbJ',
            price: 348
          }
        }
      },
      publishableKeyBtn:
        'pk_live_51PzxAsDfy7pLROjF042S02mPUf9WrzUPsWQrvrwvD7gKQ85T2JDLWji2kqAG4EVbpZaQeQ7JcvSbX8TMzQvLRBFp00CbEcH8cL'
    },
    {
      name: 'Pro +',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Infinity01Icon color={css`var(--accent-color)`} />,

      buyBtnIds: {
        duration: {
          one_month: {
            regular: 'buy_btn_1Qw9nzDfy7pLROjFBIGFcxsy',
            professional: 'buy_btn_1Qw9gVDfy7pLROjFVh150GnI',
            price: 49
          },
          three_month: {
            regular: 'buy_btn_1Qw9oHDfy7pLROjFAq77o6Ji',
            professional: 'buy_btn_1Qw9fyDfy7pLROjFnbGbOUSs',
            price: 147
          },
          one_year: {
            regular: 'buy_btn_1Qw9nTDfy7pLROjFI85JCQmr',
            professional: 'buy_btn_1Qw9fQDfy7pLROjFBJqRUys4',
            price: 588
          }
        }
      },
      publishableKeyBtn:
        'pk_live_51PzxAsDfy7pLROjF042S02mPUf9WrzUPsWQrvrwvD7gKQ85T2JDLWji2kqAG4EVbpZaQeQ7JcvSbX8TMzQvLRBFp00CbEcH8cL'
    }
  ];
  const [getUserProfile] = useLazyGetUserProfileQuery({skip: hasTofetchuser});


  const getCurrentPrice = useCallback(
    (product) => {
      const duration = selectedDurations[product.name];
      const basePrice = product.buyBtnIds.duration[duration].price;
      const calculatedPrice = isProfessionalPrice ? basePrice : basePrice * 1.2;
      const roundedPrice = Math.round(calculatedPrice * 10) / 10;
      return roundedPrice.toString().replace('.0', '');
    },
    [isProfessionalPrice, selectedDurations]
  );

  const getCurrentBtnId = useCallback(
    (product) => {
      const duration = selectedDurations[product.name];
      return isProfessionalPrice
        ? product.buyBtnIds.duration[duration].professional
        : product.buyBtnIds.duration[duration].regular;
    },
    [isProfessionalPrice, selectedDurations]
  );
  const onCloseCheckout = useCallback(() => {
      onClose?.();
      SubscriptionSignal.value = { ...SubscriptionSignal.value, isSubscribing: false };
      setLocalShowModal(false);
    }, [ onClose]);

  const onToggleProfessionnalPrice = useCallback(() => {
    setIsProfessionalPrice(!isProfessionalPrice);
  }, [isProfessionalPrice]);

  const handleProductDurationChange = useCallback((value, product) => {
    setSelectedDurations((prev) => ({
      ...prev,
      [product.name]: value
    }));
  }, []);

  useSignalEffect(() => {
      const nextVisible =  isSubscribingComputed.value;
      setLocalShowModal(nextVisible);
      if (isSubscribedComputed.value){
        getUserProfile().then(() => {
          setHasToFetchUser(false);
        }).catch((error) => {
          // Handle error
        });
      }
    }, [isSubscribingComputed]);

  const subscribedUntil = new Date( userSignal.value?.userSubscription?.subscribedUntil);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const stripePortalUrl = userSignal.value?.userSubscription?.portalLink;

  return (
   <>{localShowModal && <ModalCheckoutContainer
      closable
      visible={localShowModal}
      onClose={onCloseCheckout}
      children={
        <ModalCheckoutContent isSubscribed={isSubscribedComputed.value}>
          {isSubscribedComputed.value && subscribedUntil != null ?
            <SubscribedUntilContainer>
              <Typography fz="26px" fzSm="20px"  fw="600" lh="30px">
                {t('subscribed_until')}
              </Typography>
              <Typography color={css`var(--accent-color)`} fz="26px" fzSm="20px" fw="600" lh="30px">
                {subscribedUntil.getDate()+' '+ (months[subscribedUntil.getMonth()]) +' '+ subscribedUntil.getFullYear() }
              </Typography>
              <PortalBtn isBlocked={!hasTofetchuser && (stripePortalUrl == null || stripePortalUrl === '')} onClick={() => !hasTofetchuser && (stripePortalUrl == null || stripePortalUrl === '') ? window.open(stripePortalUrl, '_blank') : null}>
              <Typography color={css`var(--accent-color)`} fw="600" lh="16px">
                {t('stripe_portal')}
              </Typography>
              </PortalBtn>
            </SubscribedUntilContainer>
            :
            <>
              {products.map((product) => (
                <ProductContainer key={product.name} shouldHighlight={product.name !== 'Lite'}>
                  <Typography fz="26px" fw="600" lh="30px">
                    {product.name}
                  </Typography>
                  <Typography>{product.sessionsIcon || product.sessions} sessions per month</Typography>
                  <Typography>{product.programs} Programs</Typography>
                  <Typography
                    fz="26px"
                    fw="600"
                    lh="30px"
                    color={css`var(--accent-color)`}
                    style={{ justifyContent: 'center', position: 'relative', left: '16px' }}>
                    {getCurrentPrice(product)}€ /{' '}
                    <SelectCheckoutDuration
                      dropdownClassName="checkout-duration"
                      defaultValue={'one_month'}
                      onChange={(value) => handleProductDurationChange(value, product)}>
                      {product.buyBtnIds.duration.one_month != null && <Option value="one_month"> {t('subscribed_month')}</Option>}
                      {product.buyBtnIds.duration.three_month != null && (
                        <Option value="three_month"> 3 {t('subscribed_month')}</Option>
                      )}
                      {product.buyBtnIds.duration.one_year != null && <Option value="one_year"> {t('subscribed_year')}</Option>}
                    </SelectCheckoutDuration>
                  </Typography>
                  <stripe-buy-button
                    style={{ height: '44px', color: 'white' }}
                    buy-button-id={getCurrentBtnId(product)}
                    publishable-key={product.publishableKeyBtn}></stripe-buy-button>
                </ProductContainer>
              ))}
              <SwitchBtn
                onToggle={onToggleProfessionnalPrice}
                defaultChecked={true}
                leftIcon={
                  <Typography
                    lh="1.4"
                    whiteSpace="nowrap"
                    color={isProfessionalPrice ? '#FC6453' : null}
                    style={{ transition: 'color 0.5s ease-in-out' }}>
                    {' '}
                    Professional price{' '}
                  </Typography>
                }
                width="40"
                size={20}
                style={{ transition: '0.7s' }}
              />
            </>
          }

        </ModalCheckoutContent>
      }
    />}
   </>
  );
};

export default ModalCheckoutLink;
